<template lang="html">
	<v-layout column class="pb-5" style="padding-top:140px">
		<v-flex class="page-title" pb-10 :style="{'font-size':$vuetify.breakpoint.smAndUp?'70px':'50px'}">
			<v-layout row>
				<v-flex xs12 sm12 md10 lg10 xl8>
					<span class="text-appear" style="margin-right:20px;display:inline-block" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'3.8s':'0.1s'}">Privacy</span>
					<span class="text-appear" style="margin-right:20px;display:inline-block" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'3.83s':'0.13s'}">Policy</span>
				</v-flex>
			</v-layout>
			<!-- <div class="text-appear" :style="{'animation-delay':$store.getters.getFirstTimeLoadValue?'0.9s':'0.2s'}">
				here.
			</div> -->
		</v-flex>
		<v-flex class="my-15">
			<v-divider></v-divider>
		</v-flex>
		<v-flex class="py-10">
			<v-layout row>
				<v-flex xs12 md10>
					<v-layout column>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								1
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Overview
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									Imagine's intention behind publishing this Data Security and Privacy Policy is to ensure that we are compliant to the privacy and data security requirements. Herein, Imagine would like to provide detailed information regarding the data we collect, process and the controls we have implemented to safeguard the information provided to us by Data Subjects.
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								2
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Objective
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									The purpose of this policy is to outline the practices that we adhere to with respect to:
									<ol type="i">
										<li>Data Security and Privacy Regulations including European Union General Data Protection Regulation (EU GDPR), Personal Information Protection and Electronics Documents Act (PIPEDA), Malaysia Personal Data Protection Act and any other such data privacy regulations</li>
										<li>Statutory and Regulatory requirements such as HIPAA(Health Insurance Portability and Accountability Act)</li>
										<li>Data Security, Confidentiality and Privacy requirements specified as part of Master Services Agreement (MSA), Statement of work (SOW) etc. by customers</li>
										<li>Intellectual Property Rights of Data Subjects and customers</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								2.1
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Terms & Definitions
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<table>
										<tr>
											<th>Terms</th>
											<th>Definition</th>
										</tr>
										<tr v-for="(row, i) in definitionsTableData" :key="i">
											<td>{{ row.term }}</td>
											<td>{{ row.definition }}</td>
										</tr>
									</table>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								3
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Policy Description
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									Many countries have introduced legislations placing controls on collection, processing and transmission of PII.<br />
									We ensure to perform our services abiding to such laws and ensuring data security, privacy and confidentiality
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									What data / information we collect
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.1
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Customers
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>
											Prospective customers may provide their information while contacting us through our website for business opportunities
											<ol type="a">
												<li>We collect name, company details, email id (professional email id), and phone number</li>
												<li>Access to such PII is provided only on need-to-know basis and is restricted to those individuals, affiliates or subcontractors who are subjected to Imagine's strict confidentiality obligations and disciplinary policies</li>
												<li>Adequate controls are implemented to safeguard the PII which includes physical, technical and administrative controls</li>
											</ol>
										</li>
										<li>Imagine is a new tech startup offering a suite of applied AI solutions for global organizations. A ValueLabs Group company, Imagine brings together a team of business and technology leaders with a distinguished background in building AI-powered solutions that deliver real business results.</li>
										<li>
											Now, we’ve come together to create the Imagine Portfolio, a suite of applied AI solutions to help your business achieve more, faster. We would like to connect to organizations such as yours to explore partnership opportunities.
											<ol type="a">
												<li>We receive information including name, email id, phone number, role, organization associated with from professional service providers such as LinkedIn premium service, marketing databases, prospective customer websites, references from our existing clients</li>
												<li>We assume that the data subjects have provided consent to such professional service providers to share the PII with Imagine</li>
											</ol>
										</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.2
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Customers Information
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>To provide effective services to our customers we collect PII which includes name, professional email id, phone number and company address</li>
										<li>The respective customer provides us with the information during the contract phase in order to perform services effectively (billing, invoicing, program management etc.)</li>
										<li>Imagine assumes that the customer organization has already obtained consent from the data subject and would exclude Imagine from any additional consent to be acquired</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.3
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>In the process of recruitment and talent management, Imagine's HR may receive PII from Job Boards, Social Networking sites & other referral channels</li>
										<li>Imagine receives name, email ID, mobile number, address and other information provided by data subject in the respective job portals </li>
										<li>Imagine assumes that the job posting portals has already obtained consent from the data subjects to share such information and would exclude Imagine from any of the obligations related to additional consent management</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.4
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Imagine Employees PII
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>HR at Imagine collects PII of employees while issuing the offer letters and during onboarding </li>
										<li>Information includes name, address, email id, phone number and emergency contact details</li>
										<li>By accepting the offer, the employee by default consents to allow Imagine to share PII with customers, affiliates and  third party as may be required in relation to employment</li>
										<li>Access to PII is provided only on need-to-know basis and is restricted to those individuals, affiliates or subcontractors who are subjected to Imagine’ strict confidentiality obligations and disciplinary policies</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.5
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Customer (Data Controller) provided information
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>PII / PHI data collection is done by Imagine’ Customers and is shared with Imagine for access / processing / storage during the services provided to them</li>
										<li>Data subjects consent will be obtained for such collection by the customers </li>
										<li>Imagine employees always ensure that they access such data only after written consent from Customer (SOW / MSA / meeting minutes shared with Customer) </li>
										<li>Project owners reach out to ISMG to understand the regulatory and compliance requirements prior to any such processing and thoroughly read the MSA </li>
										<li>Customers to inform Imagine in case the data subject has withdrawn the consent so Imagine would erase such data as appropriate </li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.6
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Vendors/Business partners
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>To provide & initiate effective NDA, MSA & Purchase Order, we collect vendor’s data such as Name, Address, Email, Contact details, GST, PAN, Company registration details, Client list & Bank Details etc., by sending email for onboarding Vendors in SAP.</li>
										<li>Access to PII is provided only on need-to-know basis and is restricted to those individuals from Admin & Finance team, who are subjected to Imagine strict confidentiality obligations and disciplinary policies</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.7
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Consultant / Interns
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>We collect PII related data through Personal History Record (PHR form) and while calling the candidate for a job opportunity</li>
										<li>Data collected by HR-Tag : (To name a few)  Name, Contact number, Email id,  Address, blood group, DOB, Passport number, CTC details , Nationality, country, Place of birth</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								4.8
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Website data and the user behavior
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>The marketing team uses Google Tag Manager and Google Analytics to track user behavior on the website.</li>
										<li>
											The process given below is usually followed for every page on the website:
											<ol type="a">
												<li>Creation of tags for all the clickable actions on a webpage.</li>
												<li>Complete user behavior for all the tags that are stored in Google Analytics.</li>
												<li>Team also uses GA to get the number of conversions for any form on the website.</li>
												<li>Monitoring of user behavior data like number of visits, time spent; bounce rate, source, geo, etc. through Google Analytics.</li>
											</ol>
										</li>
										<li>The data filled by a visitor on any form present on the website is transferred to the Marketing team. The team categorizes this data into different buckets and assigns respective owners for the same. Qualified contacts are moved into the central CRM database.</li>
										<li>The cookies settings on our websites are set to "allow cookies" for best browsing experience possible. User's consent is obtained by them clicking on "Accept" in the notification pop-up about cookies which appears at the bottom right-hand side corner of the page as soon as they visit our websites</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								5
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Data processing
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								5.1
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Customers
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>In order to establish this connection, we would like to reach out to prospective customers with technological / digital propositions and solutions relevant to their business, invitations for our sales reach events, white papers, publications, industry newsletters and any relevant technology related content</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								5.2
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>PII data of prospective employees are collected through job portals for recruitment and talent acquisition team will process/use the collected data to reach out to prospective employees for job openings and careers at Imagine</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								5.3
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>PII collected from employees would be processed as per our HRIS practices and fulfil the obligation of our people policy.</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								5.4
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Customer (Data Controller) provided information
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Processing of the PII / PHI shared by the Customers for providing the relevant services will be processed by Imagine as per the Statement of work and / or Master Services agreement, approved business requirement and / or written instruction only</li>
										<li>Imagine would ensure the integrity of the personal data while processing by applying the required controls</li>
										<li>Imagine would implement appropriate security controls in the application developed to ensure the confidentiality and privacy of the PII / PHI to avoid unauthorized access or disclosure of such PII / PHI </li>
										<li>
											Project owners along with the help of the IT and SOC teams at Imagine would ensure adequate security controls (including the controls defined in MSA/SOW) are deployed in the project environment. The controls shall include but not limited to:
											<ol type="a">
												<li>Authentication and authorization</li>
												<li>Encryption of data while transmitting over a network and storage</li>
												<li>Provision for emergency access</li>
												<li>Data anonymization</li>
												<li>Log management where application / database should maintain logs of all processing etc.</li>
											</ol>
										</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								5.5
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Vendor/Business Partners
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Data Processing of the PII / PHI shared by the vendors for providing the relevant services will be processed by Imagine as per the Non-Disclosure Agreement, Purchase Order and Master Service agreement, approved business requirement and / or written instruction only</li>
										<li>Imagine would ensure the integrity of the personal data while processing</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								5.6
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Consultant / Interns
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>After collection of Data, if Consultant / Interns is joining with Imagine we transfer the data to HR Operations team</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								6
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Data Storage
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								6.1
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Customers
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>PII provided by the prospect in the website or gathered through marketing database shall be stored on a well-established CRM tool </li>
										<li>Appropriate technical controls shall include but not limited to access control mechanism, encryption, data anonymization are in place to safe guard the confidentiality, integrity and availability of the information</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								6.2
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>PII provided by the prospective employees in the website or job portals shall be stored on a well-established HRIS Tool</li>
										<li>Appropriate technical controls shall include but not limited to access control mechanism, encryption, data anonymization are in place to safe guard the confidentiality, integrity and availability of the information</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								6.3
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>PII provided by the employees during on-boarding would be stored on a well-established HRIS Tool</li>
										<li>Appropriate technical controls shall include but not limited to access control mechanism, encryption, data anonymization are in place to safe guard the confidentiality, integrity and availability of the information</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								6.4
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Customer (Data Controller) provided information
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Project owners should ensure that the PII / PHI data storage is limited to Customer environment only</li>
										<li>Project Owners should restrain from copying the data in Imagine environment and educate the project resources on the legal / compliance obligations if such actions are performed.</li>
										<li>Most of the Data protection and privacy regulatory requirements restrain transmitting of PII and PHI data beyond the specified geographical regions. Project owner should ensure such restrictions are addressing (for e.g. Securing a virtual environment, storage in that geographical location etc) and restrain transmission of such data in to Imagine environment.</li>
										<li>In case of storage of PII / PHI data is one of the project / service requirement, Project owners or resources should  ensure written consent from the Customer (in MSA or SOW or email approval from Customer) is obtained prior to transmission</li>
										<li>Project owners should reach out to security operation center to implement technical controls like DLP, Web content filtering etc. to safe guard data if storage is done within Imagine. </li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								6.5
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Vendor/Business Partner
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>PII provided by vendors during on-boarding would be stored on a SAP Tool</li>
										<li>Appropriate technical controls including but not limited to access control mechanism, encryption, data anonymization are in place to safe guard the confidentiality, integrity and availability of the information</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								6.6
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Consultant / Interns
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Data is stored in Excel sheets and PHR forms (Hard / soft copy) which is transferred to HR Operations team</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								7
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Data Retention & disposal
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Imagine shall retain the data of its employees in order to verify re-hire cases.</li>
										<li>Customers and their end users data (which may include PII or PHI data) shall not be retained and disposed as soon as they are no longer required for processing by Imagine</li>
										<li>PII / PHI of data subjects shall not be retained by Imagine for a duration longer than necessary. Such requirements shall be identified during data collection process based on regulatory or legal requirements prevailing during that period</li>
										<li>PII / PHI Data will be securely disposed once it is no longer in use according to the Data Retention and Disposal procedure</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								8
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Data Disclosure
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Imagine ensures that PII / PHI data is not disclosed to the unauthorized users without proper consent</li>
										<li>Any such request for access to the data from third parties including law enforcement and government agencies would be notified to Data Subject where applicable</li>
										<li>Disciplinary actions would be initiated as per the disciplinary policy defined for any unauthorized disclosure of PII / PHI</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Data Subject rights
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.1
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Right of Access, Modify
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.2
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Clients and Employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Data subjects at all times can reach out to Imagine through "email id: privacy@imagine.tech" for access to the personal data to review, modify and correct any inaccuracies</li>
										<li>For Customer provided information, we request Customer to inform Imagine in case the data subject has withdrawn the consent so Imagine can take actions on such PII as appropriate</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.3
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Employees can reach out to relevant HR business partner for access, review, modification and correction of such data</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.4
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Vendor/Business Partner
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Vendors at all times can reach out to Imagine Procurement team through email: procurement@imagine.tech for modification and correction of such data of any inaccuracies</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.5
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Consultant / Interns
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Respective candidate can reach out to concerned recruiter through email for any modifications, corrections of such data provided by them for any inaccuracies</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.6
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Right to consent / opt out consent
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.7
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Clients
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>In case of data obtained through Premium services, Imagine will reach out to all such prospective clients with an email to obtain their consent providing a link to this policy</li>
										<li>In case the data subject would like to opt out they can reply to the email or web link option provided. In case, we do not receive the information within one week it would be deemed that consent is not provided</li>
										<li>Imagine would maintain name, LinkedIn ID, or marketing database ID of opted out data subjects in do-not-contact(DNC) list to ensure that no future contacts are made by our sales team</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.8
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Prospective Employees
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Imagine will reach out to all such prospective employees with an email to obtain their consent providing a link to this policy</li>
										<li>In case the data subject would like to opt out they can reply to the email or web link option provided. In case, we do not receive the information within one week it would be deemed that consent is not provided</li>
										<li>Imagine would maintain name, LinkedIn ID, job portal ID of opted out data subjects in do-not-contact(DNC) list to ensure that no future contacts are made by our HR team</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								9.9
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Right to Erase
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Data subjects at all times can reach out to Imagine through "email id: privacy@imagine.tech" to erase a part of data or complete data </li>
										<li>For Customer provided information, we request customer to inform Imagine in case the data subject has made such request</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								10
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Breach Notification
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Imagine would intimate the data subjects, customers on any instance of data breach which could potentially impact the privacy of data subject</li>
										<li>Such notifications where ever feasible would be within 72 hours or as per the contracts established</li>
										<li>Imagine would further take all reasonable steps to curb such instance from repeating and take all corrective measure to minimize the impact of such data breach</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								11
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Data Processing and Data Controlling
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								11.1
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Imagine will be acting as a data controller & processor
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>If they are determining the purpose and the means of collecting PII data from internal employees & prospect candidates and processing it</li>
										<li>If they are determining the purpose and the means of collecting PII data from other regions where GDPR applies and processing the data and processing it (Ex: Marketing team collecting details on any company events)</li>
										<li>If the contractual agreement states that Imagine will be acting as data controller and as a data processor</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								11.2
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Imagine will be acting as a data controller
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>If they are determining the purpose of and by which means the data is processed when collecting PII data from internal employees & prospect candidates </li>
										<li>If they are determining the purpose of and by which means the data is processed when collecting PII data from other regions where GDPR applies (Ex: Marketing team collecting details on any company events)</li>
										<li>If the contractual agreement states that Imagine will be acting as data controller</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								11.3
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Imagine will be acting as a data processor
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>If they are processing the PII data collected from internal employees & prospect candidates </li>
										<li>If they are processing the PII data collected from other regions where GDPR applies</li>
										<li>If the contractual agreement states that Imagine will be acting as data processor</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								12
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Organization Controls
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>Imagine has ensured that there is a Data Protection Officer (DPO) nominated for the Data security and privacy</li>
										<li>Appropriate controls such as DLP, Web Control Filtering, IDS, IPS etc. are be implemented to ensure there is no Data Leakage</li>
										<li>Imagine has mandated Project owners to understand the compliance requirements thoroughly which are in the MSA and educate the same to the project resources</li>
										<li>Legal and ISMG team would prepare the MSA trackers and share it to the project owners / resources upon request</li>
										<li>Imagine has mandated that all compliance requirements related to any statutory, regulatory and or contractual requirements are explicitly captured in the MSA / SOW</li>
										<li>Imagine ensures that awareness sessions w.r.t data privacy and security are conducted for all relevant employees</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								13
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Intellectual property
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									<ol type="i">
										<li>All work products developed for Imagine and / or its customers including but not limited to code, test cases, test data, presentations, proof of concepts, marketing collaterals etc. are intellectual property of Imagine  and / or its customers (and / or as defined in the MSA)</li>
										<li>Employees, contractors working on behalf of Imagine are strictly prohibited to share these work products over internet to unauthorized users, transfer these in to personal folders, drives etc</li>
										<li>In case users have been given access to the customers work product, users are restrained to transfer such work products in to Imagine environment without written consent from the customers</li>
										<li>Project owners should ensure that they read out the Intellectual properties clause in the MSA and educate resources in the projects to provide their services accordingly</li>
									</ol>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								14
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Enforcement
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									Legal and ISMG would ensure that the policy is enforced and implemented thoroughly.  Any employee found to have violated this policy shall be subjected to disciplinary action.
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex class="privacy-section">
							<div class="privacy-section-number" :style="{'width':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'24px':'20px', 'line-height':$vuetify.breakpoint.smAndUp?'42px':'38px'}">
								15
							</div>
							<v-layout column>
								<v-flex class="privacy-section-title" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'28px':'24px'}">
									Review
								</v-flex>
								<v-flex class="privacy-section-content" :style="{'padding-left':$vuetify.breakpoint.smAndUp?'100px':'70px', 'font-size':$vuetify.breakpoint.smAndUp?'18px':'14px'}">
									This policy shall be reviewed once in a year, or in case of compulsive changes, whichever is earlier
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
		<!--<v-flex style="margin-top:120px">
			<ContactUs contactHeader="Curious about what Imagine could do for your business?" contactSubtitle="Let us know a few details and an Imagine expert will be in touch." :interestedProducts="[]" />
		</v-flex>-->
	</v-layout>
</template>

<script>
//import ContactUs from '../ContactUs'
export default {
	name: 'PrivacyPolicyPage',
	components: {
		//ContactUs
	},
	data() {
		return {
			definitionsTableData: [
				{
					term: 'MSA',
					definition: 'Master Service Agreements'
				},
				{
					term: 'SOW',
					definition: 'Statement of Work'
				},
				{
					term: 'HIPAA',
					definition: 'Health Insurance Portability and Accountability Act'
				},
				{
					term: 'Personally Identifiable Information (PII)',
					definition: 'Any data that could potentially identify a specific individual. Any information that can be used to distinguish one person from another and can be used for de-anonymizing anonymous data can be considered PII.'
				},
				{
					term: 'Protected Health Information (PHI)',
					definition: 'Any information about health status, provision of health care, or payment for health care that is created or collected and can be linked to a specific individual'
				},
				{
					term: 'Processing of PHI / PII',
					definition: 'Processing means any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction'
				},
				{
					term: 'EU GDPR',
					definition: 'European Union General Data Protection regulation'
				},
				{
					term: 'Data Controller',
					definition: 'Any natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data; where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its nomination may be provided for by Union or Member State law'
				},
				{
					term: 'Data Processor',
					definition: 'Means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller'
				},
				{
					term: 'Data Subject',
					definition: 'Any natural or legal person providing its PII'
				},
				{
					term: 'ISMG',
					definition: 'Information Security Management Group'
				},
				{
					term: 'PIMS',
					definition: 'Personal Information Management System'
				},
				{
					term: 'IPS',
					definition: 'Intrusion Prevention System'
				},
				{
					term: 'IDS',
					definition: 'Intrusion Detection System'
				},
				{
					term: 'DLP',
					definition: 'Data Loss Prevention'
				},
				{
					term: 'SOC',
					definition: 'Security Operations Center'
				},
				{
					term: 'HRIS',
					definition: 'Human Resources Information System'
				}
			]
		}
	},
	created() {
	}
}
</script>

<style lang="css" scoped>
.page-title{
	font-family: JostBold;
	line-height:1.2;
	animation-delay:0.8s;
}
.privacy-section{
	position:relative;
	margin: 20px 0
}
.privacy-section-number{
	position:absolute;
	font-family: JostBold;
	text-align:right;
	padding-right:20px;
	top:0;
	left:0;
}
.privacy-section-title{
	font-family: JostBold;
}
.privacy-section-content{
	padding: 10px 0;
}
table{
	border-collapse: collapse;
	width:100%
}
th{
	font-family: JostMedium;
	font-weight: normal;
	padding:5px;
	padding-right:15px;
}
td{
	padding:5px;
	padding-right:15px;
}
td:nth-child(1){
	width:25%
}
tr:nth-child(even){
	background:rgba(53, 56, 57, 0.1)
}
.text-appear{
	transform:translateY(150%);
	animation-name:text-appear;
	animation-duration:0.7s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
	opacity:0
}
@keyframes text-appear {
	from{
		transform:translateY(150%);
		opacity:0;
	}
	to{
		transform:translateY(0);
		opacity:1
	}
}
</style>
